function clickArrow() {
	const arrows = document.querySelectorAll('.icon-icon-arrow-down');

	arrows.forEach((arrow) => {
		arrow.addEventListener('click', () => {
			const section = findNextVisibleSection(arrow);
			if (section) {
				section.scrollIntoView({behavior: 'smooth'});
			}
		});
	});
}

function findNextVisibleSection(arrow) {
	const sections = document.querySelectorAll('.experience-component');

	for (const section of sections) {
		const sectionRect = section.getBoundingClientRect();
		const arrowRect = arrow.getBoundingClientRect();

		// Check if the section is below the arrow
		if (sectionRect.top > arrowRect.top) {
			return section;
		}
	}

	return null; // Return null if no section is found below the arrow
}

function hasBottomClass(section) {
	return section.querySelector('.simple-bottom') || section.querySelector('.offset-bottom');
}

function hideArrowIfNoSection() {
	const arrows = document.querySelectorAll('.icon-icon-arrow-down');

	arrows.forEach((arrow) => {
		const section = findNextVisibleSection(arrow);
		if (!section) {
			arrow.classList.add('no-arrow');
		} else {
			const parentSection = arrow.closest('.experience-component');
			if (parentSection && hasBottomClass(parentSection)) {
				arrow.classList.add('no-arrow');
			} else {
				arrow.classList.remove('no-arrow');
			}
		}
	});
}

// Call clickArrow function to set up the click event listeners
clickArrow();

// Hide the arrows on load if no section is found below them
hideArrowIfNoSection();

// We'll find out if there's a herofullwidth element and turn the header into an h1 only if it's the first instance
var heroFullWidths = document.querySelectorAll('.pd-p-singlepagelayout .pd-c-herofullwidth');
var firstInstanceFound = false;

heroFullWidths.forEach(function(heroFullWidth) {
	var h2Elements = heroFullWidth.querySelectorAll('h2');

	if (!firstInstanceFound && h2Elements.length > 0) {
		firstInstanceFound = true;
		var h2Element = h2Elements[0];
		var h1Element = document.createElement('h1');
		h1Element.className = 'title-size-xlarge';
		h1Element.innerHTML = h2Element.innerHTML;
		h2Element.parentNode.replaceChild(h1Element, h2Element);
	}
});


